export type DataSource = ProductSource | ProviderSource;

export enum ProductSource {
  AnthemBlueCross = 'anthem_blue_cross_ca',
  BlueCrossBlueShield = 'blue_cross_blue_shield_fl',
  Direct = 'direct',
  HealthOS = 'health_os',
  OptimalCare = 'optimal_care',
  PremeraBlueCross = 'premera_blue_cross',
  UnitedHealthCare = 'united_health_care',
  Cigna = 'cigna',
  Humana = 'humana',
  MockDemoInsurer = 'mock-demo-insurer',
  Centene = 'centene',
  Elevance = 'elevance',
}

export enum ProviderSource {
  MyCare = 'mycare',
  Aledade = 'aledade',
  MaxHealth = 'maxhealth',
  AledadeSearchCare = 'aledade-search-care',
  Agilon = 'agilon',
  Stellar = 'stellar',
  Premier = 'premier',
  MdiclPark = 'mdiclpark',
  BrimhallPediatrics = 'brimhall-pediatrics',
  Archwell = 'archwell',
  Privia = 'privia',
  Vytalize = 'vytalize',
  VytalizeTest = 'vytalize-test',
  Mock = 'mock',
  Yuvo = 'yuvo',
  Caap = 'caap',
  Credo = 'credo',
  Concerto = 'concerto',
  Empassion = 'empassion',
  Honest = 'honest',
  PearlHealth = 'pearlhealth',
  MockDemoPartner = 'mock-demo-partner',
  Evolent = 'evolent',
  Apixio = 'apixio',
  Allymar = 'allymar',
  Teladoc = 'teladoc',
}

export type ContentSupplierIdentifiersValues = { memberToken: string; contentSupplierId: string };

export type PatientContentSupplierIdentifiers = Partial<
  Record<DataSource, ContentSupplierIdentifiersValues>
>;

export type PatientSourceVaultToken = {
  source: string;
  sourceVaultToken: string;
};
