import React, { useCallback, useState } from 'react';
import { ThemeContext, ThemeVariablesWrapper, themes } from '@getvim/components-hooks-use-theme';
import '../resources/styles/login.less';
import { close, sendUserLoginAttempt } from '../logic/messaging';
import { useConnectLoginEvents } from '../hooks';
import { SeamlessLogin } from './seamless-login';
import { Templates } from '../atomic';
import { analyticsClient, VimConnectSeamlessAnalyticsEventTypes } from '../logic/analytics';
import { LoginSwitch } from './LoginSwitch';
import { Login as LoginCommonTypes } from '@getvim/vim-connect';

export default function Login() {
  const {
    ehrCredentials,
    credentialsMetadata,
    reLoginConfig,
    organizationId,
    contactDetails,
    seamlessLoginConfiguration,
  } = useConnectLoginEvents();

  ThemeContext(themes.vim);

  const {
    isSeamlessLoginFailed,
    widgetTimeout,
    shouldUseSeamlessLogin,
    isSeamlessSucceeded,
    seamlessCredentials,
    autoApprove,
  } = seamlessLoginConfiguration ?? {};
  const [userDeniedSeamless, setSeamlessDenied] = useState(false);
  const onSeamlessDenied = useCallback(() => {
    analyticsClient.track(
      VimConnectSeamlessAnalyticsEventTypes.VimConnectSeamlessConsentDenied,
      {},
    );
    sendUserLoginAttempt(LoginCommonTypes.LoginMethodType.SEAMLESS_LOGIN, {
      errorMessage: 'User denied seamless login',
      isCancelled: true,
    });
    setSeamlessDenied(true);
  }, [setSeamlessDenied]);
  const goBackToSeamlessLogin = useCallback(() => {
    analyticsClient.track(VimConnectSeamlessAnalyticsEventTypes.VimConnectWentBackToSeamless, {});
    setSeamlessDenied(false);
  }, [setSeamlessDenied]);

  const onClose = useCallback(() => {
    close(true, LoginCommonTypes.LoginMethodType.CREDENTIALS);
  }, []);

  return (
    <ThemeVariablesWrapper theme={themes.vim}>
      {shouldUseSeamlessLogin && !userDeniedSeamless && !reLoginConfig ? (
        <SeamlessLogin
          onSeamlessDenied={onSeamlessDenied}
          widgetTimeout={widgetTimeout ?? 0}
          isSeamlessSucceeded={isSeamlessSucceeded}
          seamlessCredentials={seamlessCredentials}
          ehrCredentials={ehrCredentials}
          organizationId={organizationId}
          autoApprove={autoApprove}
        />
      ) : (
        <Templates.LoginWidgetWrapper className="login" onCloseClicked={onClose}>
          <main className="popover-body">
            {LoginSwitch({
              ehrCredentials,
              credentialsMetadata,
              reLoginConfig,
              organizationId,
              contactDetails,
              isSeamlessLoginFailed,
              wasSeamlessLoginShownAndDenied: userDeniedSeamless,
              goBackToSeamlessLogin,
            })}
          </main>
        </Templates.LoginWidgetWrapper>
      )}
    </ThemeVariablesWrapper>
  );
}
